.nav-mobile{
font-size: 18pt;
}
.nav ul {
  list-style: none;
  padding: 0;
}
.nav-title{
  z-index: 2000;
}
.nav-title a{
  color: inherit;
  text-decoration: none; 
 
 
}

.nav-item a{
  color: inherit;
  text-decoration: none; 
}

.nav-item .link:hover {
  color: grey;
}

.nav-item .link:focus {
  color: rgb(218, 0, 0);
}

.nav-item .link-active {
  color: rgb(218, 0, 0);
}

.nav-item .link-active:hover {
  color: rgb(147, 0, 0);
}

.offcanvas{
  --bs-offcanvas-width: 100%;
  --bs-offcanvas-transition: transform 0.15s ease-out;

}