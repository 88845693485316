@media (min-width:600px) {

   
   
}
.indicatorsContainer{
    display: none;


}
.indicatorsContainer{
   
    display: flex;
    
    


}




.carouselIndicator{

    box-sizing: border-box;
display: inline-block;
padding: 4px;
margin-right: 10px;


border: 1px solid ;
 background-color: #fff;
 /* border-radius: 50%; */
 
}

.carouselIndicatorActive{
background-color: black;
border: 1px solid ;
}