.proj-title{
margin: 0;
}
.proj-details{
 color: rgb(100, 100, 100);
}


.proj-desc p{
  white-space: pre;
  text-wrap: balance;
  
}

.proj-media-text p{
  white-space: pre;
  text-wrap: balance;
  
}
/* .proj-desc a{
  display: block;
  padding-top: 5px;

   
 } */
.proj-desc a:hover{
 color: rgb(218, 0, 0);
  
}

.proj-media-text a{
  display: block;
  padding-top: 5px;

   
 }
.proj-media-text a:hover{
 color: rgb(218, 0, 0);
  
}

.whitespace{
  white-space: pre-line;
}

.quote{
  font-style:italic;
}

/* Content of projects */

/* .proj-title
.proj-details
.proj-featured
.proj-description
.proj-media




.subsection-gallery {
  max-height: 500px;
  position: relative;
  display: inline-grid;
  grid-column-gap: 30px;
} */

/* Text content of projects */
/* .subsection-text-block {
  width: 400px;
  margin-right: 30px;
  height: 500px;
  overflow-y: hidden;
  position: relative;
  display: inline;
  grid-row: 1;
} */

/* .subsection-text-tech {
  margin-top: 30px;
  color: grey;
} */

/* .subsection-text-lang1 {
  margin-top: 25px;
} */

/* .subsection-text-lang2 {
  margin-top: 25px;
  color: grey;
} */

/* Image content of projects */
/* .subsection-img {
  display: inline;
  grid-row: 1;
} */

/* Video content of projects */
/* .subsection-video {}

.subsection-audio {} */

/* YOUTUBE */

.proj-media-youtube
 {
	
	
  
  
}
.youtube{
  background-color: #000;
  
	
	width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content:center;
}



.youtube iframe {
	aspect-ratio: 16 / 9;
	width: 100%;
  height: 100%;
  
}


.soundcloud {
  height:100%;
}
.soundcloud iframe {
  height:100%;
}
/* .videoyt {
	aspect-ratio: 16 / 9;
	width: 100%;
  
} */

.proj-media-divider{
  display: block;
  margin: 12px;
}

.headerInsideText{
  margin-bottom: 10px;
}

.externalLink{
  margin-top: 10px;
}