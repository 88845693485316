html {
  
  padding:0;
  margin: 0;
  box-sizing: border-box;
  
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

body {
  overflow-y: scroll;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-stretch: expanded;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
}

